<template>
    <div>
    
        <h1 class="mt-2 mb-4">{{trans('create-contract',175)}}</h1>

        <clients-profile-contract-wizard-zzp-component v-if="itemData.id_document_const_type == 22" :itemData.sync="itemData" />
        <clients-profile-contract-wizard-av-aivg-component v-if="itemData.id_document_const_type == 23 || itemData.id_document_const_type == 24" :itemData.sync="itemData" />
        <clients-profile-contract-wizard-upload-document-component class="mt-3" :itemData.sync="itemData" v-if="user_data.id_user_const_role < 3" />

    </div>
</template>

<script>
import ClientsProfileContractWizardZzpComponent from './Components/ClientsProfileContractWizardZzpComponent.vue';
import ClientsProfileContractWizardAvAivgComponent from './Components/ClientsProfileContractWizardAvAivgComponent.vue';
import ClientsProfileContractWizardUploadDocumentComponent from './Components/ClientsProfileContractWizardUploadDocumentComponent.vue';

export default {
    components: {
        ClientsProfileContractWizardZzpComponent,
        ClientsProfileContractWizardAvAivgComponent,
        ClientsProfileContractWizardUploadDocumentComponent
    },

    props: ["itemData"],

    created(){
        this.user_data = JSON.parse(localStorage.getItem('user'));
    },
        
    data(){
        return{
            user_data: null
        }
    },
    
    methods:{
        
    }
};
</script>

<style lang="scss" scope>
.app-local-line {
    width:100%; 
    border-top: 1px solid #e6e6e6 !important;  
    margin-top:5px !important ;
    margin-bottom: 10px !important;   
}
</style>
