<template>
    <div>
        
        <h1 class="mt-2 mb-4">{{trans('create-contract',175)}}</h1>

        <b-row v-if="userSignature == null">
            <b-col cols="12 mt-2" class="mb-3">
                <b-alert variant="warning" show class="mb-0">
                    <div class="font-weight-600 text-base">{{trans('missing-signature',180)}}</div>
                    <div class="text-small" >{{trans('click', 175)}} <b-link @click="creatSignature">{{trans('here', 175)}}</b-link> {{trans('client-user-signature',174)}}</div>    
                </b-alert> 
            </b-col>
        </b-row>
        
        <b-row class="match-height mb-3">
            <b-col md="6" class="pr-md-2 mb-sm-3 mb-md-0">

                <b-card body-class="pb-3">
                    <b-row class="app-card-header mb-3">
                        <b-col start>
                            <div class="app-card-tittle">
                                <div> {{trans('cp-organisation-data',192)}} </div>    
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col start>
                            <p class="col-list-label mb-3">{{trans('organization_name',169)}}</p>

                            <p class="col-list-label">{{trans('street',182)}}</p>
                            <p class="col-list-label">{{trans('house-number',182)}}</p>
                            <p class="col-list-label">{{trans('cw-house-number-addition',194)}}</p>
                            <p class="col-list-label">{{trans('postcode',182)}}</p>
                            <p class="col-list-label mb-3">{{trans('town',192)}}</p>

                            <p class="col-list-label mb-3">{{trans('kvk_number',169)}}</p>
                        </b-col>
                        <b-col>
                            <p class="col-form-value mb-3">{{clientData.client_name}}</p>

                            <p class="col-form-value">{{clientData.address.street}}</p>
                            <p class="col-form-value">{{clientData.address.house_number}}</p>
                            <p class="col-form-value">{{clientData.address.number_additional ? clientData.address.number_additional : '-'}}</p>
                            <p class="col-form-value">{{clientData.address.post_code}}</p>
                            <p class="col-form-value mb-3">{{clientData.address.city}}</p>

                            <p class="col-form-value mb-3">{{clientData.kvk_number}}</p>
                        </b-col>
                    </b-row>

                    <hr class="app-local-line" /> 

                    <app-row-left-label >
                        <app-select v-model="cItemData.client_contact" :label="trans('cw-signatory',194)" :args.sync="client_contacts_args"  type="getClientContacts" validatorRules="required" validatorName="Tekenbevoegde" />
                    </app-row-left-label>

                    <app-row-left-label>
                        <app-select v-model="cItemData.client_contact_second" :label="trans('cw-send-contract-to',194)" :args.sync="client_contacts_args" type="getClientContacts" validatorRules="required" :validatorName="trans('cw-send-contract-to',194)"/>
                    </app-row-left-label>

                </b-card>

            </b-col>

            <b-col md="6" class="pl-md-2">

                <b-card class="mb-3" body-class="pb-3">
                    <b-row class="app-card-header mb-3">
                        <b-col start>
                            <div class="app-card-tittle">
                                <div> {{trans('happynurse-data',3)}} </div>    
                            </div>
                        </b-col>
                    </b-row>

                    <app-row-left-label >
                        <app-select  v-model="cItemData.client_office" :label="trans('branch',182)" :disable="false" type="getUserLocations" validatorRules="required" validatorName="Vestiging"/>
                    </app-row-left-label>

                    <app-row-left-label >
                        <app-select v-model="cItemData.office_user" :label="trans('contactperson',169)" type="getUsersFromLocation" :refreshIndex.sync="refresh_users" :args.sync="cItemData.office_location_args" :disable="cItemData.client_office.value < 1" validatorRules="required" validatorName="Contactpersoon" />
                    </app-row-left-label>

                    <app-row-left-label >
                        <app-select  v-model="cItemData.account_manager" :label="trans('cw-key-account-manager',194)" type="getAccountManagersList" :args.sync="office_location_second_args" validatorRules="required" validatorName="Key accountmanager"/>
                    </app-row-left-label>
                </b-card>   


                <b-card body-class="pb-3">
                    <b-row class="app-card-header mb-3">
                        <b-col start>
                            <div class="app-card-tittle">
                                <div> {{trans('contract-type',182)}} </div>    
                            </div>
                        </b-col>
                    </b-row>

                    <app-row-left-label>
                        <app-radio-group v-model="cItemData.id_document_const_type" :options="contract_type" validatorName="Contract" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
                    </app-row-left-label>
                </b-card>

            </b-col>
        </b-row>
        <clients-profile-contract-uzk-component v-if="cItemData.id_document_const_type == 23 || cItemData.id_document_const_type == 24" :itemData.sync="cItemData"/>
        <clients-profile-contract-work-time-component v-if="cItemData.id_document_const_type == 22" :periods.sync="itemData.periods" :setValues.sync="itemData.set_values"/>
        
    </div>
</template>

<script>
import ClientsProfileContractUzkComponent from './ClientsProfileContractUzkComponent.vue';
import ClientsProfileContractWorkTimeComponent from './ClientsProfileContractWorkTimeComponent.vue';

export default {
    components: {
        ClientsProfileContractWorkTimeComponent,
        ClientsProfileContractUzkComponent

    },

    props: ["itemData", "clientData", "userSignature"],

    created(){

        if(this.cItemData.office_user != null){
            this.office_location_second_args.id_office_user = this.cItemData.office_user.value;
        }

        if(this.itemData.client_office != null){
            this.office_location_second_args.id_office_data_location = this.itemData.client_office.value;
        }       
    },

    watch:{
        "cItemData.client_office": {
            handler: function(val){
                if(this.cItemData.office_user != null && !JSON.parse(val.users).includes(this.cItemData.office_user.value) ){
                    this.cItemData.office_user.name = null;
                    this.cItemData.office_user.value = null;
                    this.cItemData.office_user = null;
                }
                this.cItemData.office_location_args.id_office_data_location = this.itemData.client_office.value;
                this.office_location_second_args.id_office_data_location = this.itemData.client_office.value;
                this.refresh_users ++;
                this.refresh_contact ++;
            },
            deep: true,
        },

        "cItemData.office_user": {
            handler: function(val){
                if(val){
                    this.office_location_second_args.id_office_user = val.value;
                }
            },
            deep: true
        },

    },

    computed: {
        cItemData: {
            get() {return this.itemData },
            set(value) {this.$emit('update:itemData', value) },
        },
    },

    data(){
        return{

            client_contacts_args: {id_client_data_main: this.clientData.id_client_data_main},
            office_location_second_args: {id_office_data_location:  null, id_office_user: null},
            refresh_users: 1,
            refresh_contact: 1,
            contact_second_refresh: 1,
            from: null,
            to: null,

            contract_type: [
                                { value: 22, text: this.trans('cw-agency-agreement',194) },
                               // { value: 23, text: this.trans('cw-broadcasting-av-hn',194) },
                                { value: 24, text: this.trans('cw-broadcasting-aivg',194) },
                            ],

        
        }
    },

    methods:{
        creatSignature(){
            this.$router.push({ name: "user-signature-pad", params: { idUserSignature: JSON.parse(localStorage.getItem('user')).id, returnType: "client_contract_wizard", returnIdType: this.clientData.id_client_data_main} });
        }
    }
};
</script>

<style lang="scss" scope>
.app-local-bold-text{
    font-weight:700;
    color:#174066;
}

.app-local-line {
    width:100%; 
    border-top: 1px solid #e6e6e6 !important;  
    margin-top:5px !important ;
    margin-bottom: 10px !important;   
}
</style>
