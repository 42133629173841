<template>
    <div>
        
        <b-card class="mb-3" body-class="pb-3">
            <b-row class="app-card-header mb-3">
                <b-col start>
                    <div class="app-card-tittle">
                        <div> {{trans('cw-upload-contract',194)}} </div>    
                    </div>
                </b-col>
            </b-row>

            <app-row-left-label :slots="[6, 6]" :isMarginBetween="false" class="mb-2">
                <template v-slot:col-1>
                    <app-check-box id="ch_cost_place" v-model="cItemData.upload_contract" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" :rightLabel="trans('add-contract-title',3)" v-on:change="cancelContract()"/>
                </template>
            </app-row-left-label> 

            <b-row v-if="cItemData.upload_contract == 1 && cItemData.documents.length > 0">
                <b-col>
                    <b-row v-for="document in cItemData.documents" :key="document.file_path">
                        <b-col md="7" xl="7">
                            <span class="app-documents-item"><div class="w-100 text-truncate">{{ document.file_name }}</div></span>
                            <span class="app-documents-item" v-if="document.id_document_const_type == 5 || document.id_document_const_type == 6"> | {{setFormatDate(document.date_to)}}</span>
                            <span class="app-documents-item" v-else-if="document.id_document_const_type == 4 || document.id_document_const_type == 9"> | {{setFormatDate(document.date_from)}}</span>
                        </b-col>
                        <b-col md="3" xl="3" class="text-right">
                            <span class="app-documents-item font-weight-bolder"> {{ document.file_size_mb }} MB</span>
                        </b-col>
                        <b-col md="2" xl="2">
                            <b-link href="#" @click="cancelContract()">
                                <span class="app-documents-item" v-if="f_delete == false">{{trans('remove',175)}}</span>
                                <b-spinner class="ml-2" small v-if="f_delete" />
                            </b-link>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row v-if="cItemData.upload_contract == 1">
                <b-col>
                    <div class="image-upload-wrap" v-if="(cItemData.documents.length < 1)">
                        <input class="file-upload-input" type="file" v-on:change="addFile" accept="application/pdf"/>
                        <div class="drag-text">
                            <img src="@/assets/images/icons/download-cloud.svg" height="40px" v-if="!f_uploading" />
                            <b-spinner v-if="f_uploading" class="spinner-margin" />
                            <div>
                                <small class="text-muted">{{trans('drag-or-select-document-pdf',191)}}</small>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <small v-if="cItemData.documents_error == 1 && cItemData.documents.length == 0 && cItemData.upload_contract == 1" class="text-danger">{{trans('cp-attachment-required-error',191)}}</small> 

        </b-card>

    </div>
</template>

<script>
import axios from "@axios";

export default {
    props:['itemData'],

    components:{
        
    },

    created(){
        this.user_data = JSON.parse(localStorage.getItem('user'));
    },

    computed: {
        cItemData: {
            get() {return this.itemData },
            set(value) {this.$emit('update:itemData', value) },
        },
    },


    data(){
        return{
            accept_files: {
                                default: "application/pdf",
                                type: String
                            },
            f_uploading: false,
            f_delete: false,
            form_data: new FormData(),
            user_data: null
        }
    },

    methods: {
        addFile(e) {

            var file = e.target.files[0];

            if(!file.type.match('application/pdf.*')) {
                alert(this.trans('only-pdf-files',191));
                file = "";
                return;
            }

            this.form_data.append("id_client_data_main", this.cItemData.id_client_data_main);
            this.form_data.append("file", file);
            this.form_data.append("file_name", file.name);
            this.form_data.append("id_document_const_type", this.cItemData.id_document_const_type);
            this.form_data.append("file_size", file.size);
            this.form_data.append("file_size_mb", (file.size / 1024 / 1024).toFixed(2));

            this.sendFile();

        },

        sendFile(){
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };

            this.f_uploading = true;

            axios
                .post("core/addTempFile", this.form_data, config)                
                .then((response) => {
                    this.form_data.append("temp_path", response.data);

                    var object = {};
                    this.form_data.forEach((value, key) => object[key] = value);                    
                    this.cItemData.documents.push(object);
                    
                    this.form_data = new FormData();
                    this.f_uploading = false;

                })
                .catch(function(error) {
                    this.f_uploading = false;
                    console.log(error);
                });
        },

        cancelContract() {            
            if(this.itemData.documents.length > 0 ) {
                this.f_delete = true;

                axios
                    .post("core/removeTempFile",  {
                                documents: JSON.stringify(this.itemData.documents),
                            })
                    .then((response) => {
                        this.cItemData.documents = [];
                        this.f_delete = false;
                    })
                    .catch(function(error) {
                        this.f_delete = false;
                        console.log(error);
                    });
            }
        }, 
    }

    
}
</script>

<style scope>
.file-upload-content {
    display: none;
    text-align: center;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.image-upload-wrap {
    border: 1px dashed #BBE7FA;
    border-radius: 5px;
    position: relative;
    transition: 1s;
}

.image-dropping,
.image-upload-wrap:hover {
    background-color: #f8fcfe;
}
.drag-text {
    text-align: center;
    padding: 20px 10px;
}

.drag-text p {
    color: #000000;
    font-size: 12px;
}

.fileContainer {
    font-size: 12px;
    border: none;
    padding: 0 0 10px 0;
}

.fileContainer:hover {
    background-color: transparent;
}

.deleteButton {
    color: #71e6ee;
    cursor: pointer;
    float: left;
}

.files {
    width: 43%;
}

.loading {
    margin-left: 40%;
}

.uploadIcon {
    width: 13%;
}

</style>