<template>
    <div>
        <b-card class="mb-3" body-class="pb-3">
            <b-row class="app-card-header mb-3">
                <b-col start>
                    <div class="app-card-tittle">
                        <div> {{trans('cp-prices',190)}} </div>    
                    </div>
                </b-col>
            </b-row>

            <app-row-left-label :slots="[6, 6]" :isMarginBetween="false" class="mb-2">
                <template v-slot:col-1>
                    <app-input v-model="cItemData.send_rate" :label="trans('cp-broadcast-agency-rate',190)" validatorName="Bemiddelingstarief uitzenden" validatorRules="required" type="decimal_02"/>
                </template>
                <template v-slot:col-2>
                    <app-input v-model="cItemData.detachment_rate" :label="trans('cp-mediation-rate-for-posting',190)" validatorName="Bemiddelingstarief detachering" validatorRules="required" type="decimal_02"/>
                </template>

            </app-row-left-label> 

            <hr class="app-local-line" /> 

            <b-row>
                <b-col class="mb-1">
                    <div class="flabel">{{trans('cw-select-certified-reservations-and-risky-actions',194)}}</div>
                    <div class="filters">
                        <b-button class="app-local-btn" :class="{ 'btn-secondary-grey': !selected_all, 'app-list-button-selected':  selected_all }" v-on:click="selectAll()" id="all">
                            {{trans('select-all',175)}}
                        </b-button>
                        <b-button class="app-local-btn" :class="{ 'btn-secondary-grey': !item.selected, 'app-list-button-selected':  item.selected }" v-for="item in cItemData.zzp_rates" :key="item.id_function_data_main" data-toggle="tooltip" :title="item.function_name" v-on:click="selectOne(item)">
                            {{item.short_name}}
                        </b-button>
                    </div>
                    <small v-if="cItemData.zzp_error == 1 && cItemData.zzp_rates.filter(({selected}) => selected == 1).length == 0" class="text-danger">{{trans('select-at-least-one-function',180)}}</small> 
                </b-col>
            </b-row>
            
            <b-row v-for="(el, index) in cRowCount" :key="el">
                
                <b-col cols="12">
                    <b-row>
                        <b-col cols="3">
                        </b-col>
                        <b-col cols="4" align="center" v-if="cItemDataSelected[(index * 2)] != undefined">
                            <label class="flabel">{{cItemDataSelected[(index * 2)].short_name}}</label>
                        </b-col>
                        <b-col cols="4" align="center" v-if="cItemDataSelected[(index * 2) + 1] != undefined">
                            <label class="flabel">{{cItemDataSelected[(index * 2) + 1].short_name}}</label>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col cols="12">
                    <b-row>
                        <b-col cols="3"/>                
                        <b-col cols="4" align="center" v-if="cItemDataSelected[(index * 2)] != undefined">
                            
                            <app-row-left-label :slots="[6, 6]">
                                <template v-slot:col-1>
                                    <label class="flabel ">{{trans('from',182)}}</label>
                                </template>
                                <template v-slot:col-2>
                                    <label class="flabel ">{{trans('until',182)}}</label>
                                </template>
                            </app-row-left-label>
                            
                        </b-col>

                        <b-col cols="4" align="center" v-if="cItemDataSelected[(index * 2) + 1] != undefined">

                            <app-row-left-label :slots="[6, 6]">
                                <template v-slot:col-1>
                                    <label class="flabel ">{{trans('from',182)}}</label>
                                </template>
                                <template v-slot:col-2>
                                    <label class="flabel ">{{trans('until',182)}}</label>
                                </template>
                            </app-row-left-label>

                        </b-col>
                    </b-row>
                </b-col>

                <b-col cols="3">
                        <app-row-left-label v-for="item in cItemData.periods.shifts" :key="item.type">
                            <label class="flabel ">{{item.display_name}}</label>
                        </app-row-left-label>
                </b-col>

                <b-col cols="4">
                    <app-row-left-label :slots="[6, 6]" v-for="(item_rate, idx) in cItemDataSelected[(index * 2)].rates" :key="item_rate.id_type">
                        <template v-slot:col-1>
                            <app-input v-model="item_rate.from" :name="'inputx_'+idx" :id="'inputx_'+idx" :key="itemData.refresh_value" :validatorId="idx+'a'+item_rate.id_function_data_main" :validatorName="'min_' + item_rate.period_name + '_' + item_rate.period_type" :validatorRules="(item_rate.to != null && parseFloat(item_rate.to.replace(',', '.')) > 0 ? 'required' : '')" type="decimal_03" :validatorCustomMessage="{ 'required': trans('cw-minimum-rate-error',194) }" />
                        </template>
                        <template v-slot:col-2> 
                            <app-input v-model="item_rate.to" :validatorName="'max_' + item_rate.period_name + '_' + item_rate.period_type" :validatorRules="(item_rate.from != null && parseFloat(item_rate.from.replace(',', '.')) > 0 ? 'app-min-value:'+parseFloat(item_rate.from.replace(',', '.')) : '')" :validatorCustomMessage="{ 'app-min-value': trans('cw-max-rate-too-low-error',194), 'required': trans('cw-maximum-rate-error',194) }" type="decimal_03"/>
                        </template>
                    </app-row-left-label>
                </b-col>

                <b-col cols="4" v-if="cItemDataSelected[(index * 2) + 1] != undefined">
                    <app-row-left-label :slots="[6, 6]" v-for="(item_rate, idx) in cItemDataSelected[(index * 2) + 1].rates" :key="item_rate.id_type">
                        <template v-slot:col-1>
                            <app-input v-model="item_rate.from" :name="'inputy_'+idx" :id="'inputy_'+idx" :key="itemData.refresh_value" :validatorId="idx+'b'+item_rate.id_function_data_main" :validatorName="'min_' + item_rate.period_name + '_' + item_rate.period_type" :validatorRules="(item_rate.to != null && parseFloat(item_rate.to.replace(',', '.')) > 0 ? 'required' : '')" type="decimal_03" :validatorCustomMessage="{ 'required': trans('cw-minimum-rate-error',194) }" />
                        </template>
                        <template v-slot:col-2> 
                            <app-input v-model="item_rate.to" :validatorName="'max_' + item_rate.period_name + '_' + item_rate.period_type" :validatorRules="(item_rate.from != null && parseFloat(item_rate.from.replace(',', '.')) > 0 ? 'app-min-value:'+parseFloat(item_rate.from.replace(',', '.')) : '')" :validatorCustomMessage="{ 'app-min-value': trans('cw-max-rate-too-low-error',194), 'required': trans('cw-maximum-rate-error',194) }" type="decimal_03"/>
                        </template>
                    </app-row-left-label>
                </b-col>

                <hr class="app-local-line" v-if="cItemDataSelected[(index * 2) + 2] != undefined"/> 
               
            </b-row>

        </b-card>

        <b-row class="match-height">
            <b-col md="6" class="pr-md-2 mb-sm-3 mb-md-0">
                <b-card body-class="pb-3">
                    <b-row class="app-card-header mb-3">
                        <b-col start>
                            <div class="app-card-tittle">
                                <div> {{trans('travel-costs',192)}} </div>    
                            </div>
                        </b-col>
                    </b-row>

                    <app-row-left-label>
                        <app-radio-group v-model="cItemData.km_allow" :options="opt" :label="trans('cw-expenses-reimbursed',194)" :disabled="(user_data.id_user_const_role == 3 && ([0, 1].includes(cItemData.id_client_type) || cItemData.id_client_type == null) )"/>
                    </app-row-left-label>

                    <app-row-left-label :slots="[6, 6]" v-if="cItemData.km_allow == 1">
                        <template v-slot:col-1>
                            <app-input v-model="cItemData.cost_house_work" :disabled="cItemData.km_allow == 0 || (user_data.id_user_const_role == 3 && ([0, 1].includes(cArgs.id_client_type) || cArgs.id_client_type == null))" :label="trans('cw-home-work',194)" type="decimal_01"/>
                        </template>
                        <template v-slot:col-2> 
                            <app-input v-model="cItemData.cost_work_work" :disabled="cItemData.km_allow == 0 || (user_data.id_user_const_role == 3 && ([0, 1].includes(cArgs.id_client_type) || cArgs.id_client_type == null))" :label="trans('cw-work-work',194)" type="decimal_01"/>
                        </template>
                    </app-row-left-label>   

                    <app-row-left-label v-if="cItemData.km_allow == 1">
                        <app-input v-model="cItemData.max_km_each_way" :label="trans('max-number-of-km-per-shift',192)" :validatorRules="cItemData.max_km_each_way != null && cItemData.max_km_each_way > 0 ? 'app-min-value:30' : ''" :validatorCustomMessage="{ 'app-min-value': trans('cw-minimal-travel-distance',194) }" type="integer_03" />
                    </app-row-left-label>   
                </b-card>
            </b-col>

            <b-col md="6" class="pl-md-2">
                <b-card body-class="pb-3">
                    <b-row class="app-card-header mb-3">
                        <b-col start>
                            <div class="app-card-tittle">
                                <div> {{trans('cw-validity',194)}} </div>    
                            </div>
                        </b-col>
                    </b-row>

                    <app-row-left-label>
                        <app-date v-model="cItemData.date_from" :label="trans('cw-rate-valid-from',194)" validatorName="Tarief geldig van" :validatorRules="'required|date-check|date-more-than:'+min_date" :validatorCustomMessage="{ 'date-more-check': trans('cw-start-date-error',194), 'disable-date-past': trans('cw-end-date-error',194) }" :disabledDatesPass="disabled_days_before" :disabledDatesRange="disabledDatesRange"/>
                    </app-row-left-label>

                    <app-row-left-label>
                        <app-date v-model="cItemData.date_to" :label="trans('cw-rate-valid-until',194)" validatorName="Tarief geldig tot" :validatorRules="'required|date-check|date-more-check|date-more-than:'+cItemData.date_from" :disabledDatesPass="30"/>
                    </app-row-left-label>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from "@axios";
import moment from "moment";

export default {
    props:['itemData'],

    components:{
    },

    created(){
        this.getFunctionButtons();
        this.user_data = JSON.parse(localStorage.getItem('user'));
    },

    computed: {
        cItemData: {
            get() {return this.itemData },
            set(value) {this.$emit('update:itemData', value) },
        },

        cItemDataSelected : {
            get() { 
                    if(this.itemData.zzp_rates != null){
                        return this.cItemData.zzp_rates.filter(({selected}) => selected == 1);
                    }else{
                        return 0;
                    }
                },
        },

        cRowCount:{
            get() {
                    
                    if(this.itemData.zzp_rates != null){
                        var selected_count = this.cItemData.zzp_rates.filter(({selected}) => selected == 1).length;
                        return parseInt(selected_count / 2) + selected_count%2;
                    }else{
                        return 0;
                    }
                
                }
        }
    },


    data(){
        return{
            data_test: "",
            loading: false,
            user_data: null,
            selected_all: 0,
            disabled_days_before: null,
            disabledDatesRange: null,
            min_date: null,
            last_contract_date: null,
            opt: [ { value: 1, text: this.trans('yes-option',3) }, { value: 0, text: this.trans('no-option',3) }],
        }
    },

    methods: {
        getFunctionButtons(){
            this.loading = true;

            axios
                .post("clients/profile/contract-wizard/getFunctionButtons", {
                    id_client_data_main: this.itemData.id_client_data_main
                })
                .then((res) => {           
                    this.cItemData.zzp_rates = res.data.functions;
                    this.last_contract_date = res.data.last_active_contract.date_from;
                    this.prepareRates();
                    this.prepareDatepicker();
                })
                .catch((error) => {
                   console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = true;
                });  
        },

        selectAll(){
            
            if(this.selected_all == 0){
                this.selected_all = 1;

                this.cItemData.zzp_rates.forEach((item) => {
                    item.selected = 1;
                });
                
            }else{
                this.selected_all = 0;

               this.cItemData.zzp_rates.forEach((item) => {
                    item.selected = 0;
                });

            }
        },

        selectOne(item){
            if(item.selected == 1){
                item.selected = 0;
                this.selected_all = 0;

            }else{
                item.selected = 1;
            }
            
        },

        prepareRates(){
            this.cItemData.zzp_rates.forEach((item) => {
                
                this.cItemData.periods.shifts.forEach((item_period) => {
                    item.rates.push({"id_function_data_main": item.id_function_data_main,
                                     "period_type": item_period.type,
                                     "period_from": item_period.from,
                                     "period_to": item_period.to, 
                                     "period_name": item_period.display_name,
                                     "from": null,
                                     "to": null});
                });

            });
        },

        prepareDatepicker(){
            if(this.last_contract_date == null){
                this.disabled_days_before = 15;
                this.min_date = moment().subtract(15, "days").toDate();
            }else{
                this.disabledDatesRange = '(date < new Date('+moment(this.last_contract_date,"DD-MM-YYYY")+'))';
                this.min_date = this.last_contract_date;
            }
        },
    }

    
}
</script>

<style scope>
.app-local-btn{
    margin-top: 10px;
    border-color: #D0D5DD;
}

.app-local-btn:hover{
    background-color: #dcf0f9;
    border-color: #066791;
    box-shadow: 0px 0px 5px -2px #0d7eb3 !important;
    color: #344054 !important;
}



</style>