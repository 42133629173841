<template>
    <div>
        <b-card class="mb-3" body-class="pb-1">
            <b-row class="app-card-header mb-3">
                <b-col start>
                    <div class="app-card-tittle">
                        <div> {{trans('cw-contract-duration',194)}} </div>    
                    </div>
                </b-col>
            </b-row>

            <app-row-left-label :slots="[6, 6]" :isMarginBetween="false" class="mb-2">
                <template v-slot:col-1>
                    <app-input v-model="cItemData.loan_value" :label="trans('loan-volume',192)" validatorName="Inleenvolume" validatorRules="required" type="integer_05"/>
                </template>

                <template v-slot:col-2>
                    <app-select  v-model="cItemData.cao" :label="trans('cao',182)" :disable="false" validatorName="CAO" validatorRules="required" :args="cItemData" type="getSectionsShortcuts"/>
                </template>
            </app-row-left-label> 

            <app-row-left-label :slots="[6, 6]" :isMarginBetween="false" class="mb-2">
                <template v-slot:col-1>
                    <app-input v-model="cItemData.duration_year" :label="trans('duration-year',192)" validatorName="Looptijd" validatorRules="required|min_value:1" type="integer_02" v-on:blur="calculateDateTo()" />
                </template>

                <template v-slot:col-2>

                    <app-row-left-label :slots="[6, 6]" class="mb-2">
                        <template v-slot:col-1>
                            <app-date v-model="cItemData.date_from" :label="trans('date-from',182)" validatorName="Startdatum" :validatorRules="'required|date-check|date-more-than:'+min_date" :validatorCustomMessage="{ 'date-more-check': trans('cw-start-date-error',194), 'disable-date-past': trans('cw-end-date-error',194) }" v-on:input="calculateDateTo()" :disabledDatesPass="disabled_days_before" :disabledDatesRange="disabledDatesRange" :key="refresh_date_from" :disabled="loading_functions"/>
                        </template>

                        <template v-slot:col-2>
                            <app-date v-model="cItemData.date_to" :label="trans('date-to',182)" validatorName="Eindatum" :disabled="true" :key="refresh_date_to"/>
                        </template>
                    </app-row-left-label> 

                </template>
            </app-row-left-label> 
        </b-card>
                    
        <b-card class="mb-3" body-class="pb-3">
            <b-row class="app-card-header mb-3">
                <b-col start>
                    <div class="app-card-tittle">
                        <div> {{trans('cp-prices',190)}} {{cItemData.uzk_parameters.calculation_type == 1 ? trans('cw-minus-hourly-rate',194) : trans('cw-minus-conversion-factor',194)}}</div>    
                    </div>
                </b-col>
                <b-col cols="auto" end>
                    <b-spinner class="align-middle" small v-if="loading_functions"></b-spinner>  
                </b-col>
            </b-row>

            <b-row class="pr-3" v-if="sort_matrix != null && sort_matrix.length > 0">
                <b-col cols="3">
                </b-col>
                <b-col align="center" v-if="cItemData.uzk_parameters.diff_assigment == 1">
                    <label class="flabel"> {{trans('broadcast',192)}} </label>
                </b-col>
                <b-col class="app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.diff_assigment == 1">
                    <label class="flabel"> {{trans('detachment',182)}} </label>
                </b-col>
                <b-col align="center" v-if="cItemData.uzk_parameters.diff_assigment == 0">
                    <label class="flabel">{{trans('cw-temporary-employment-and-secondment',194)}}</label>
                </b-col>
            </b-row>

            <b-row class="pr-3" v-if="sort_matrix != null && sort_matrix.length > 0">
                <b-col cols="3">
                    <label class="flabel"> {{trans('function',3)}}</label>
                </b-col>
                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-phase-a',193)" labelId="p_a" :itemData="cItemData" :copyObject.sync="copy_object"/>
                </b-col>
                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-phase-b',193)" labelId="p_b" :itemData="cItemData" :copyObject.sync="copy_object"/>
                </b-col>
                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-phase-c',193)" labelId="p_c" :itemData="cItemData" :copyObject.sync="copy_object"/>
                </b-col>
                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.diff_phase == 0">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-all-phases',193)" labelId="p_all" :itemData="cItemData" :copyObject.sync="copy_object"/>
                </b-col>
                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.calculation_type == 2 && cItemData.uzk_parameters.diff_overhour == 1">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-ort',194)" labelId="ort" :itemData="cItemData" :copyObject.sync="copy_object"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1 && cItemData.uzk_parameters.diff_assigment == 1">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-phase-a',193)" labelId="ap_a" :itemData="cItemData" :copyObject.sync="copy_object"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1 && cItemData.uzk_parameters.diff_assigment == 1">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-phase-b',193)" labelId="ap_b" :itemData="cItemData" :copyObject.sync="copy_object"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1 && cItemData.uzk_parameters.diff_assigment == 1">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-phase-c',193)" labelId="ap_c" :itemData="cItemData" :copyObject.sync="copy_object"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.diff_phase == 0 && cItemData.uzk_parameters.diff_assigment == 1">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-all-phases',193)" labelId="ap_all" :itemData="cItemData" :copyObject.sync="copy_object"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.calculation_type == 2 && cItemData.uzk_parameters.diff_overhour == 1 && cItemData.uzk_parameters.diff_assigment == 1">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-ort',194)" labelId="a_ort" :itemData="cItemData" :copyObject.sync="copy_object"/>
                </b-col>
            </b-row>            

           <b-row class="pr-3" v-for="item in sort_matrix" :key="item.value">
                <b-col cols="3">
                    <label class="flabel font-weight-300">{{item.function_name}}</label>
                </b-col>

                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1">
                    <app-input class="mb-1" v-model="item.p_a" :type="cType" :key="refresh"/>
                </b-col>
                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1">
                    <app-input class="mb-1" v-model="item.p_b" :type="cType" :key="refresh"/>
                </b-col>
                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1">
                    <app-input class="mb-1" v-model="item.p_c" :type="cType" :key="refresh"/>
                </b-col>
                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.diff_phase == 0">
                    <app-input class="mb-1" v-model="item.p_all" :type="cType" :key="refresh"/>
                </b-col>
                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.calculation_type == 2 && cItemData.uzk_parameters.diff_overhour == 1" >
                    <app-input class="mb-1" v-model="item.ort" :type="cType" :key="refresh" :customErrorClass="cErrorClass ? 'app-local-ort-error app-local-error-align' : 'app-local-error-align'" :validatorRules="getOrtRules(item, 0)" :validatorId="item.id+'p'" :validatorName="trans('cw-ort',194)"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1 && cItemData.uzk_parameters.diff_assigment == 1">
                    <app-input class="mb-1" v-model="item.ap_a" :type="cType" :key="refresh"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1 && cItemData.uzk_parameters.diff_assigment == 1">
                    <app-input class="mb-1" v-model="item.ap_b" :type="cType" :key="refresh"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1 && cItemData.uzk_parameters.diff_assigment == 1">
                    <app-input class="mb-1" v-model="item.ap_c" :type="cType" :key="refresh"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.diff_phase == 0 && cItemData.uzk_parameters.diff_assigment == 1">
                    <app-input class="mb-1" v-model="item.ap_all" :type="cType" :key="refresh"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.calculation_type == 2 && cItemData.uzk_parameters.diff_overhour == 1 && cItemData.uzk_parameters.diff_assigment == 1">
                    <app-input class="mb-1" v-model="item.a_ort" :type="cType" :key="refresh" :customErrorClass="cErrorClass ? 'app-local-ort-error app-local-error-align' : 'app-local-error-align'" :validatorRules="getOrtRules(item, 1)" :validatorId="item.id+'a'" :validatorName="trans('cw-ort',194)" />
                </b-col>
            </b-row>      

            <b-alert variant="light" show class="p-3 mb-3 app-local-height d-flex align-items-center" v-if="sort_matrix == null || (sort_matrix != null && sort_matrix.length == 0) ">
                <div v-if="loading_functions" class="text-small">{{trans('cw-load-functions',194)}}</div>
            </b-alert> 
        </b-card>
        
        <b-row class="match-height">
            <b-col md="6" class="pr-md-2 mb-sm-3 mb-md-0">
                <b-card body-class="pb-3">
                    <b-row class="app-card-header mb-3">
                        <b-col start>
                            <div class="app-card-tittle">
                                <div> {{trans('cw-taking-a-temporary-worker',194)}} </div>    
                            </div>
                        </b-col>
                    </b-row>

                    <app-row-left-label class="mb-2">
                        <app-input v-model="cItemData.hour_value" :label="trans('number-of-hours',192)" :additionalInfo="trans('cw-how-many-hours-free-charge',194)" validatorName="Aantal uur" validatorRules="required" type="integer_04" />
                    </app-row-left-label> 
                    <app-row-left-label class="mb-2">
                        <app-memo v-model="cItemData.hour_value_content" :maxLength="200" lettersCounter :label="trans('cw-uzk-content',194)" />
                    </app-row-left-label>
                </b-card>
            </b-col>

            <b-col md="6" class="pl-md-2">
                <b-card body-class="pb-3">
                    <b-row class="app-card-header mb-3">
                        <b-col start>
                            <div class="app-card-tittle">
                                <div> {{trans('travel-costs',192)}} </div>    
                            </div>
                        </b-col>
                    </b-row>

                    <app-row-left-label>
                        <app-radio-group v-model="cItemData.km_allow" :options="opt" :label="trans('cw-expenses-reimbursed',194)" :disabled="(user_data.id_user_const_role == 3 && ([0, 1].includes(cItemData.id_client_type) || cItemData.id_client_type == null) )"/>
                    </app-row-left-label>

                    <app-row-left-label :slots="[6, 6]" v-if="cItemData.km_allow == 1">
                        <template v-slot:col-1>
                            <app-input v-model="cItemData.cost_house_work" :disabled="cItemData.km_allow == 0 || (user_data.id_user_const_role == 3 && ([0, 1].includes(cItemData.id_client_type) || cItemData.id_client_type == null) )" :label="trans('cw-home-work',194)" type="decimal_01"/>
                        </template>
                        <template v-slot:col-2> 
                            <app-input v-model="cItemData.cost_work_work" :disabled="cItemData.km_allow == 0 || (user_data.id_user_const_role == 3 && ([0, 1].includes(cItemData.id_client_type) || cItemData.id_client_type == null) )" :label="trans('cw-work-work',194)" type="decimal_01"/>
                        </template>
                    </app-row-left-label>   

                    <app-row-left-label v-if="cItemData.km_allow == 1">
                        <app-input v-model="cItemData.max_km_each_way" :label="trans('max-number-of-km-per-shift',192)" :validatorRules="cItemData.max_km_each_way != null && cItemData.max_km_each_way > 0 ? 'app-min-value:30' : ''" :validatorCustomMessage="{ 'app-min-value': trans('cw-minimal-travel-distance',194) }" type="integer_03" />
                    </app-row-left-label>   
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import moment from "moment";
import axios from "@axios";
import ClientsProfileContractWizardAvAivgHeaderComponent from './ClientsProfileContractWizardAvAivgHeaderComponent.vue';

export default {
    props:['itemData'],

    components:{
        ClientsProfileContractWizardAvAivgHeaderComponent
        
    },

    created(){
        this.user_data = JSON.parse(localStorage.getItem('user'));
        if(this.cItemData.cao && this.cItemData.cao.value != null){
            this.getFunctions();
        }

    },

    watch: {
        "cItemData.cao":{
            handler: function(val) {
                if(val != null && val.value != null){
                    this.getFunctions()
                }
                
            },
        },

        "copy_object.refresh":{
            handler: function(val) {
               this.copyValues();                
            }
        },
    },

    computed: {
        cItemData: {
            get() {return this.itemData },
            set(value) {this.$emit('update:itemData', value) },
        },
        cType: {
            get() {
                if(this.cItemData.uzk_parameters.calculation_type == 1){
                    if(this.cItemData.uzk_parameters.diff_assigment == 0){
                        return 'decimal_02';
                    }else {
                        return 'decimal_03';
                    }
                }else{
                    if(this.cItemData.uzk_parameters.diff_assigment == 0){
                        return 'decimal_01_places_04';
                    }else {
                        return 'decimal_03_places_04';
                    }
                }
                

            }
        },

        cErrorClass: {
            get(){
                if(this.cItemData.uzk_parameters.calculation_type == 2 && this.cItemData.uzk_parameters.diff_overhour == 1 && this.cItemData.uzk_parameters.diff_phase == 1 && this.cItemData.uzk_parameters.diff_assigment == 1 ){
                    return true;
                } else {
                    return false;
                }
            }
        }
    },

    data(){
        return{
            loading_functions: false,
            refresh_date_to: 0,
            refresh_date_from: 0,
            disabled_days_before: null,
            disabledDatesRange: null,
            last_contract_date: null,
            min_date: null,
            items: null,
            refresh: 0,
            opt: [ { value: 1, text: this.trans('yes-option',3) }, { value: 0, text: this.trans('no-option',3) }],
            user_data: null,
            sort_matrix: [],
            copy_object: {
                column_from: null,
                column_to: null,
                operation_type : null,
                refresh: 0,
            }
            
        }
    },


    methods: {
        calculateDateTo(){
           
            if(this.cItemData.date_from != null && this.cItemData.duration_year != null){
                 
                var date_from = moment(this.cItemData.date_from, 'DD-MM-YYYY').toDate();
                var future_month = (moment(date_from).add(this.cItemData.duration_year, 'years')).subtract(1, 'days');
                this.cItemData.date_to = moment(future_month).format('DD-MM-YYYY');
                this.refresh_date_to ++;
            }
        },

        getFunctions(){
            this.disabledDatesRange = null;
            this.cItemData.date_from = null;
            this.cItemData.date_to = null;
            this.refresh_date_from++;
            this.refresh_date_to++;
            this.sort_matrix = [];
            this.loading_functions = true;
            this.cItemData.functions = null;

            axios
                .post("clients/profile/contract-wizard/getFunctions", {
                    id_cao_data_main: this.itemData.cao.value,
                    id_client_data_main: this.itemData.id_client_data_main
                })
                .then((res) => {
                    this.last_contract_date = res.data.last_active_contract.date_from;
                    this.cItemData.cost_house_work = res.data.travel_costs.work_home_cost;
                    this.cItemData.cost_work_work = res.data.travel_costs.work_work_cost;
                    this.items = res.data.functions;

                    this.items.sort((a, b) => a.name.localeCompare(b.name) );

                    this.prepareInputs();
                    this.prepareDatepicker();
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_functions = false;
                });

        },

        prepareInputs(){
            
            var matrix = {};
            if (this.cItemData.uzk_parameters.diff_function == 0) {
                matrix[0] = {"function_name": "Alle", "p_all": null, "p_a": null, "p_b": null, "p_c": null, "ort": null, "ap_all": null, "ap_a": null, "ap_b": null, "ap_c": null, "a_ort": null, "description": null, "id": 0};
            } else {
                this.items.forEach(item => {
                    matrix[item.id_function_data_main] = {"function_name": item.name, "p_all": null, "p_a": null, "p_b": null, "p_c": null, "ort": null, "ap_all": null, "ap_a": null, "ap_b": null, "ap_c": null, "a_ort": null, "description": item.description, "id": item.id_function_data_main};
                });
            }
            

            this.sort_matrix = [];
            for(var i in matrix){
                this.sort_matrix.push(matrix[i]);
            }

            this.sort_matrix.sort((a, b) => a.function_name.localeCompare(b.function_name));

           if(Object.keys(this.sort_matrix).length > 0){
                this.cItemData.functions = this.sort_matrix;
           }else{
                this.cItemData.functions = null;
           }
           this.refresh++;

        },

        prepareDatepicker(){
            if(this.last_contract_date == null){
                this.disabled_days_before = 15;
                this.min_date = moment().subtract(15, "days").toDate();
            }else{
                this.disabledDatesRange = '(date < new Date('+moment(this.last_contract_date,"DD-MM-YYYY")+'))';
                this.min_date = this.last_contract_date;
            }
        },

        copyValues() {
            if (this.copy_object.operation_type == 1) { // copy first to all
                let source_value = this.sort_matrix[0][this.copy_object.column_from];
                
                this.sort_matrix.forEach(item => {
                    item[this.copy_object.column_from] = source_value;
                });
                
                this.refresh++;

            } else if (this.copy_object.operation_type == 2) { // copy column to column
                
                this.sort_matrix.forEach(item => {
                    item[this.copy_object.column_to] = item[this.copy_object.column_from];
                });
                
                this.refresh++;

            } else if (this.copy_object.operation_type == 3) { // copy shift to assigment
                this.sort_matrix.forEach(item => {
                    item["ap_a"] = item["p_a"];
                    item["ap_b"] = item["p_b"];
                    item["ap_c"] = item["p_c"];
                    item["ap_all"] = item["p_all"];
                    item["a_ort"] = item["ort"];
                });

                this.refresh++;
            } else if (this.copy_object.operation_type == 4) { // clear column
                
                this.sort_matrix.forEach(item => {
                    item[this.copy_object.column_from] = null;
                });
                
                this.refresh++;
            }
        },

        getOrtRules(item, type){
            if(type == 0){
                if((item.p_a != null && item.p_a != '')
                    || (item.p_b != null && item.p_b != '')
                    || (item.p_c != null && item.p_c != '')
                    || (item.p_all != null && item.p_all != '')){
                    return 'required';
                }
            } else{
                if((item.ap_a != null && item.ap_a != '')
                    || (item.ap_b != null && item.ap_b != '')
                    || (item.ap_c != null && item.ap_c != '')
                    || (item.ap_all != null && item.ap_all != '')){
                    return 'required';
                }
            }

            return '';
        },

        showDescription() {
            this.$root.$emit("bv::show::popover", "popover-id-function");
        },

    }

    
}
</script>

<style scoped>
.app-local-line {
    width:100%; 
    border-top: 1px solid #e6e6e6 !important;  
    margin-top:5px !important ;
    margin-bottom: 10px !important;   
}

.app-local-bg-assigment {
background-color: #F9FAFB;
}



</style>
<style>
.app-local-ort-error{
   width: 4.2vw;
}

.app-local-error-align{
    float: left;
}
</style>

