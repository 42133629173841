<template>
    <div>
        
        <b-card class="mb-3" body-class="pb-3">
            <b-row class="app-card-header mb-3">
                <b-col start>
                    <div class="app-card-tittle">
                        <div> {{trans('cp-sending',192)}}</div>    
                    </div>
                </b-col>
            </b-row>
                <app-row-left-label :slots="[6, 6]" :isMarginBetween="false" class="mt-2">
                    <template v-slot:col-1>
                        <label class="app-input-top-label">Contract obv</label>
                    </template>
                    <template v-slot:col-2>
                         <app-radio-group class="app-local-margin" v-model="cItemData.uzk_parameters.calculation_type" :options="calculation_type_options"  validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }"/>
                    </template>
                </app-row-left-label>

                <hr class="mt-1 mb-4" v-if="cItemData.uzk_parameters.calculation_type != null"/>

                <app-row-left-label :slots="[6, 6]" :isMarginBetween="false" class="mt-2" v-if="cItemData.uzk_parameters.calculation_type != null">
                    <template v-slot:col-1>
                        <label class="app-input-top-label">{{cItemData.uzk_parameters.calculation_type == 1 ? trans('cp-rate',192) : trans('cp-conversion-factor',192)}} {{trans('cw-per-function',193)}}</label>
                    </template>
                    <template v-slot:col-2>
                         <app-radio-group class="app-local-margin" v-model="cItemData.uzk_parameters.diff_function" :options="question_options"  validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }"/>
                    </template>
                </app-row-left-label>

                <app-row-left-label :slots="[6, 6]" :isMarginBetween="false" class="mt-2" v-if="cItemData.uzk_parameters.calculation_type != null">
                    <template v-slot:col-1>
                        <label class="app-input-top-label">{{cItemData.uzk_parameters.calculation_type == 1 ? trans('cp-rate',192) : trans('cp-conversion-factor',192)}} {{trans('cw-per-phase',193)}}</label>
                    </template>
                    <template v-slot:col-2>
                         <app-radio-group class="app-local-margin" v-model="cItemData.uzk_parameters.diff_phase" :options="question_options"  validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }"/>
                    </template>
                </app-row-left-label>

                <app-row-left-label :slots="[6, 6]" :isMarginBetween="false" class="mt-2" v-if="cItemData.uzk_parameters.calculation_type != null">
                    <template v-slot:col-1>
                        <label class="app-input-top-label">{{cItemData.uzk_parameters.calculation_type == 1 ? trans('cp-diffrent-rate-for-deta',192) : trans('cp-diffrent-conversion-factor-for-deta',192)}}</label>
                    </template>
                    <template v-slot:col-2>
                         <app-radio-group class="app-local-margin" v-model="cItemData.uzk_parameters.diff_assigment" :options="question_options"  validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }"/>
                    </template>
                </app-row-left-label>

                <app-row-left-label :slots="[6, 6]" :isMarginBetween="false" class="mt-2" v-if="cItemData.uzk_parameters.calculation_type == 2">
                    <template v-slot:col-1>
                        <label class="app-input-top-label">{{trans('cp-separate-ort-conversion-factor',192)}}</label>
                    </template>
                    <template v-slot:col-2>
                         <app-radio-group class="app-local-margin" v-model="cItemData.uzk_parameters.diff_overhour" :options="question_options"  validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }"/>
                    </template>
                </app-row-left-label>

                <app-row-left-label :slots="[6, 6]" :isMarginBetween="false" class="mt-2" v-if="cItemData.uzk_parameters.calculation_type == 2 && cItemData.uzk_parameters.diff_overhour == 1">
                    <template v-slot:col-1>
                        <label class="app-input-top-label">{{trans('prospect-rw-apply-ort',193)}} <b-icon icon="info-circle-fill" id="popover-target-1" class="ml-2 app-cursor-pointer" tabindex="0" style="width: 17px; height: 17px;" /></label>
                    </template>
                    <template v-slot:col-2>
                         <app-radio-group class="app-local-margin" v-model="cItemData.uzk_parameters.conversion_factor" :options="conversion_factor_options"  validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }"/>
                    </template>
                </app-row-left-label>

                <b-popover target="popover-target-1" triggers="hover focus" no-fade v-if="cItemData.uzk_parameters.calculation_type == 2 && cItemData.uzk_parameters.diff_overhour == 1">
                    <template #title>
                        {{trans('information',182)}}
                    </template>
                    <p class="app-popover-text" v-html="trans('cp-information-added-later',192)"></p>
                </b-popover>
     
        </b-card>

    </div>
</template>

<script>

export default {
    props:["itemData"],

    components:{
         
    },

    created(){
    
    },

    computed: {
        cItemData: {
            get() {return this.itemData },
            set(value) {this.$emit('update:itemData', value) },
        },
    },

    watch:{
    
    },

    data() {
        return{
            calculation_type_options: [
                { value: 1, text: this.trans('cp-rate',192) },
                { value: 2, text: this.trans('cp-conversion-factor',192) }
            ],

            conversion_factor_options: [
                { value: 1, text: this.trans('cp-ort-part',192) },
                { value: 2, text: this.trans('cp-full-hourly-wage',192) }
            ],
            
            question_options: [
                { value: 1, text: this.trans('yes-option',3) },
                { value: 0, text: this.trans('no-option',3) },
            ],            

        }
    },

    methods: {
        
    }

    
}
</script>
<style>
.app-local-margin .custom-control{
    min-width: 85px;
}
</style>
