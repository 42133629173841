<template>
  <div v-if="!loading">
    <b-row> 
            <b-col lg="10" xl="8" offset-xl="2" offset-lg="1">

                <div class="mt-3 mb-1 d-flex align-items-center" v-if="step > 0">
                    <b-link v-if="step > 1" href="#" @click="goBack" class="d-flex align-items-center"><b-icon icon="arrow-left" font-scale="1" class="mr-2"></b-icon>{{trans('previous-step',175)}}</b-link><span v-if="step > 1" class="mr-2 ml-2 text-muted"> | </span> 
                    <b-link href="#" v-if="cItemData.register_status == 1" @click="goClientCard">{{trans('back-to-prospect',189)}}</b-link>
                    <b-link href="#" v-if="cItemData.register_status == 2" @click="goClientCard">{{trans('back-to-organization',194)}}</b-link>
                </div>

                <ValidationObserver ref="form">
                    <form @submit.prevent="onSubmit">
                        <content-step-1 :itemData.sync="item_data" :clientData="client_data" :userSignature="user_signature" v-if="step === 1"/>
                        <content-step-2 :itemData.sync="item_data" v-else-if="step === 2" />
                    </form>
                </ValidationObserver>

                <div class="mt-4 mb-3" align="left">
                    <app-button type="primary" v-if="step == 1" @click="nextStep" :disabled="loading == true || user_signature == null" :loading="loading">{{trans('forward',175)}}</app-button>
                    <app-button type="primary" v-if="step == 2" @click="draftContract" :disabled="loading_send_contract == true || loading_draft_contract == true || user_signature == null" :loading="loading_draft_contract">{{trans('cw-concept-contract',194)}}</app-button>
                    <app-button type="primary" v-if="step == 2" @click="sendContract" :disabled="loading_send_contract == true || loading_draft_contract == true || user_signature == null" :loading="loading_send_contract">{{btn_label}}</app-button>
                </div>
                
            </b-col>
    </b-row> 
  </div>
</template>

<script>

import axios from "@axios";
import ContentStep1 from "./Step1/ClientsProfileContractWizardStep1.vue";
import ContentStep2 from "./Step2/ClientsProfileContractWizardStep2.vue";
import AppAlerts from '@core/scripts/AppAlerts';

import { ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import moment from "moment";

export default {
    components: { 
       ContentStep1,
       ContentStep2,
       ValidationObserver,
    },

    created() {
        this.getData();
    },

    watch:{
        "cItemData.upload_contract":{
            handler: function(val) {
                if(this.cItemData.upload_contract == 1){
                    this.btn_label = this.trans('add-contract-title',3);
                }else{
                    this.btn_label = this.trans('cw-send-contract',194);
                }
            },
        },

        "cItemData.id_document_const_type": {
            handler: function(val, oldVal) {

                if(oldVal != null){
                    this.clearData();
                }

                if(this.item_data.id_document_const_type == 22){
                    this.item_data.km_allow = 0;
                }else{
                    this.item_data.km_allow = 1;
                }
            },
        }
    },

    computed: {
        cItemData: {
            get() {return this.item_data },
            set(value) {this.$emit('update:item_data', value) },
        },
    },

    data() {
        return {
            step: 1,
            btn_label: this.trans('cw-send-contract',194),
            id_client_data_main: this.$route.params.id_client_data_main,
            item_data: {
                            refresh_value: 0,
                            id_client_data_main: null,
                            client_contracts: null,
                            register_status: null,
                            client_type: null,
                            id_client_type: null,
                            
                            client_contact: {name: "", value: null},
                            client_contact_second: null,
                            
                            office_location_args: {id_office_data_location: null},
                            client_office: {name: "", value: null},
                            office_user: {name: "", value: null},
                            account_manager: {name: "", value: null},

                            id_document_const_type: null,
                            date_from: moment(new Date()).format('DD-MM-YYYY'),
                            date_to: null,

                            //input for zzp
                            //rate: null,
                            send_rate: null,
                            detachment_rate: null,
                            cost_house_work: null, //'0,21',
                            cost_work_work: null, //'0,27',
                            max_km_each_way: null,
                            //date_from_rate: moment(new Date()).format('DD-MM-YYYY'),
                            //date_to_rate: null,
                            km_allow: 0,

                            rates: {
                                        day: {n2_from: null, n2_to: null, n3_from: null, n3_to: null, n4_from: null, n4_to: null, n5_from: null, n5_to: null},
                                        evening: {n2_from: null, n2_to: null, n3_from: null, n3_to: null, n4_from: null, n4_to: null, n5_from: null, n5_to: null},
                                        night: {n2_from: null, n2_to: null, n3_from: null, n3_to: null, n4_from: null, n4_to: null, n5_from: null, n5_to: null},
                                        evening_night: {n2_from: null, n2_to: null, n3_from: null, n3_to: null, n4_from: null, n4_to: null, n5_from: null, n5_to: null},
                                        weekend_holidays: {n2_from: null, n2_to: null, n3_from: null, n3_to: null, n4_from: null, n4_to: null, n5_from: null, n5_to: null},
                                        sleep_shift: {n2_from: null, n2_to: null, n3_from: null, n3_to: null, n4_from: null, n4_to: null, n5_from: null, n5_to: null},
                                        care_24_hours: {n2_from: null, n2_to: null, n3_from: null, n3_to: null, n4_from: null, n4_to: null, n5_from: null, n5_to: null},
                            },
                            
                            //input for av hn & aivg
                            loan_value: null,
                            cao: {name: "", value: null},
                            duration_year: null,
                            hour_value: null,
                            hour_value_content: '',
                            functions: null,

                            //uploadd contract
                            upload_contract: null,
                            documents: [],
                            zzp_rates: null,
                            periods: null,
                            zzp_error:0,
                            documents_error:0,

                            uzk_parameters: {
                                calculation_type: null,
                                diff_function: null,
                                diff_phase: null,
                                diff_assigment: null,
                                diff_overhour:null,
                                conversion_factor: null,
                            },

                            set_values: {
                                is_night_shift: 0,
                                is_saturday: 0,
                                is_sunday: 0,
                                is_sleep_shift: 0,
                                is_full_care: 0,
                                is_holidays: 0,
                                two_periods_values: [4, 17, 28],
                                tree_periods_values: [4, 17, 19, 28],
                                weekend_values: [15, 19],
                                saturday_values: [1, 25],
                                sunday_values: [1, 25],
                            },
                       },

            client_data:{
                id_client_data_main: this.$route.params.id_client_data_main,
                client_name: "",
                address: {
                            post_code: "",
                            house_number: "",
                            number_additional: "",
                            street: "",
                            city: ""
                            },
                        
                kvk_number: ""
            },

            contract_type: { 22: this.trans('cw-agency-agreement',194),
                             23: this.trans('cw-broadcasting-av-hn',194),
                             24: this.trans('cw-broadcasting-aivg',194)},

            loading: false,
            loading_send_contract: false,
            loading_draft_contract: false,
            alert_class: new AppAlerts(),
            user_signature : JSON.parse(localStorage.getItem('user')).signature,
            work_home_cost: null,
            work_work_cost: null
        };
    },

    methods: {
        getData: function(){
            
            this.loading = true;

            axios
                .get("clients/profile/contract-wizard/getData/" + this.id_client_data_main)
                .then((res) => {
                    //item_data
                    this.item_data.id_client_data_main = res.data.id_client_data_main;
                    this.item_data.client_contracts = res.data.client_contracts;
                    this.item_data.register_status = res.data.register_status;
                    this.item_data.client_type = res.data.client_type;
                    this.item_data.id_client_type = res.data.id_client_type;
                    this.item_data.cost_house_work = res.data.work_home_cost;
                    this.item_data.cost_work_work = res.data.work_work_cost;
                    this.work_home_cost = res.data.work_home_cost;
                    this.work_work_cost = res.data.work_work_cost;

                    if(res.data.id_office_data_location == null){
                        this.item_data.client_office = null;
                    }else{
                        this.item_data.client_office.name = res.data.office_location_name;
                        this.item_data.client_office.value = res.data.id_office_data_location;
                    }
                    this.item_data.office_location_args.id_office_data_location = res.data.id_office_data_location;
                    
                    if(res.data.id_user_contact == null){
                        this.item_data.office_user = null;
                    }else{
                        this.item_data.office_user.value = res.data.id_user_contact;
                        this.item_data.office_user.name = res.data.user_contact;
                    }

                    if(res.data.id_user_manager == null){
                        this.item_data.account_manager = null;
                    }else{
                        this.item_data.account_manager.value = res.data.id_user_manager;
                        this.item_data.account_manager.name = res.data.user_manager_name;
                    }
                    
                    if(res.data.id_contact_data_main == null){
                        this.item_data.client_contact = null;
                    }else{
                        this.item_data.client_contact.name = res.data.contact_name;
                        this.item_data.client_contact.value = res.data.id_contact_data_main;
                    }

                    this.item_data.cao.value = res.data.id_cao_data_main;
                    this.item_data.cao.name = res.data.cao_name;

                    //client_data
                    this.client_data.client_name = res.data.client_name;

                    this.client_data.address.post_code = res.data.post_code;
                    this.client_data.address.house_number = res.data.house_number;
                    this.client_data.address.number_additional = res.data.number_additional;
                    this.client_data.address.street = res.data.street;
                    this.client_data.address.city = res.data.city;

                    this.client_data.kvk_number = res.data.kvk_number;

                    this.loading = false;
                
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                });

        },

        nextStep(){
            
            this.validationForm().then((success) =>{
                if(success){
                    if(this.step == 1){
                        this.step = 2;
                    }

                    document.getElementById('app').scrollIntoView({
                                                                    behavior: "smooth",
                                                                    block: "start",
                                                                });
                }
            });

        },

        draftContract(){

            this.validationForm().then((success) =>{
                if(success){

                    if(this.item_data.id_document_const_type == 22 && this.cItemData.zzp_rates.filter(({selected}) => selected == 1).length == 0){
                        this.item_data.zzp_error = 1;
                        return false;
                    }

                    if(this.item_data.upload_contract == 1 && this.item_data.documents.length == 0){
                        this.item_data.documents_error = 1;
                        return false;
                    }

                    this.item_data.documents_error = 1;
                    this.item_data.zzp_error = 0;
                    this.loading_draft_contract = true;
                    
                    axios
                        .post("clients/profile/contract-wizard/downloadContract", {
                            id_client_data_main: this.id_client_data_main,
                            item_data: JSON.stringify(this.item_data),
                        },
                        {
                            responseType: 'blob', 
                            crossDomain: true,
                        })
                        .then((res) => {
                            
                            if(res.data.size != null && res.data.size > 0){
                                var contract_name = this.contract_type[this.item_data.id_document_const_type];
                                var mine_type = "application/pdf";
                                const url = window.URL.createObjectURL(new Blob([res.data], {type : mine_type}));
                                const link = document.createElement('a');
                                link.href = url;
                                link.target= "_blank";
                                mine_type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                                mine_type == 'application/msword' ||
                                mine_type == 'text/plain'
                                                ? link.setAttribute('download', contract_name):link.setAttribute('download', contract_name); 
                                link.setAttribute('open', contract_name); 
                                document.body.appendChild(link);
                                link.click();

                            }else{
                                this.alert_class.openAlertWarning(this.trans('file-not-found',180)).then(res => {});
                            }

                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                            this.loading_draft_contract = false;
                            this.alert_class.openAlertWarning(this.trans('file-not-found',180)).then(res => {})
                        })
                        .finally(() => {
                            this.loading_draft_contract = false;
                        });


                }
            });


        },

        sendContract(){
             this.validationForm().then((success) =>{
                if(success){
                    
                    if(this.item_data.id_document_const_type == 22 && this.cItemData.zzp_rates.filter(({selected}) => selected == 1).length == 0){
                        this.item_data.zzp_error = 1;
                        return false;
                    }

                    if(this.item_data.upload_contract == 1 && this.item_data.documents.length == 0){
                        this.item_data.documents_error = 1;
                        return false;
                    }

                    if(moment(this.item_data.date_from,"DD-MM-YYYY").year() == moment(this.item_data.date_to,"DD-MM-YYYY").year() && this.item_data.id_document_const_type == 22){
                        this.alert_class.openAlertConfirmation(this.trans('cw-start-end-date-in-same-year',194)).then(res => {
                            if(res == true){
                                this.item_data.zzp_error = 0;
                                this.item_data.documents_error = 0;
                                this.loading_send_contract = true;
                                axios
                                    .post("clients/profile/contract-wizard/sendContract", {
                                        id_client_data_main: this.id_client_data_main,
                                        item_data: JSON.stringify(this.item_data),
                                    })
                                    .then((res) => {  
                                        if(res.data == 2){
                                            this.goClientCard();
                                        }
                                    })
                                    .catch((error) => {
                                        console.error(`error during request: ${error}`);
                                        this.loading_send_contract = false;
                                    })
                                    .finally(() =>{
                                        this.loading_send_contract = false;
                                    });

                            }     
                        }); 
                    }else{
                        this.item_data.zzp_error = 0;
                        this.item_data.documents_error = 0;

                        this.loading_send_contract = true;
                        axios
                            .post("clients/profile/contract-wizard/sendContract", {
                                id_client_data_main: this.id_client_data_main,
                                item_data: JSON.stringify(this.item_data),
                            })
                            .then((res) => {  
                                if(res.data == 2){
                                    this.goClientCard();
                                }
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                                this.loading_send_contract = false;
                            })
                            .finally(() =>{
                                this.loading_send_contract = false;
                            });
                    }
                }
            });

        },

        validationForm() {

            return this.$refs.form.validate().then((success) => {
                if (!success) {
                    setTimeout(() => {
                        const errors = Object.entries(this.$refs.form.refs)
                            .map(([key, value]) => ({
                                key,
                                value,
                            }))
                            .filter((error) => {
                                if (!error || !error.value || !error.value.failedRules) return false;
                                return Object.keys(error.value.failedRules).length > 0;
                            });
                        if (errors && errors.length > 0) {
                            this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        }
                    }, 100);

                    return false;
                }

                return true;
            });
                
        },

        
        goBack: function(){
            
            if(this.step == 2){
                this.step = 1;
            }else if(this.step == 3){
                this.step = 2;
            }

            document.getElementById('app').scrollIntoView({
                                                                    behavior: "smooth",
                                                                    block: "start",
                                                                });
        },

        clearData(){
            this.item_data.date_from =  moment(new Date()).format('DD-MM-YYYY');
            this.item_data.date_to = null;
            //this.item_data.rate = null;
            this.send_rate = null;
            this.detachment_rate = null;
            this.item_data.cost_house_work = this.work_home_cost;
            this.item_data.cost_work_work = this.work_work_cost;
            this.item_data.max_km_each_way = null;
            this.item_data.rates = {
                                        day: {n2_from: null, n2_to: null, n3_from: null, n3_to: null, n4_from: null, n4_to: null, n5_from: null, n5_to: null},
                                        evening: {n2_from: null, n2_to: null, n3_from: null, n3_to: null, n4_from: null, n4_to: null, n5_from: null, n5_to: null},
                                        night: {n2_from: null, n2_to: null, n3_from: null, n3_to: null, n4_from: null, n4_to: null, n5_from: null, n5_to: null},
                                        evening_night: {n2_from: null, n2_to: null, n3_from: null, n3_to: null, n4_from: null, n4_to: null, n5_from: null, n5_to: null},
                                        weekend_holidays: {n2_from: null, n2_to: null, n3_from: null, n3_to: null, n4_from: null, n4_to: null, n5_from: null, n5_to: null},
                                        sleep_shift: {n2_from: null, n2_to: null, n3_from: null, n3_to: null, n4_from: null, n4_to: null, n5_from: null, n5_to: null},
                                        care_24_hours: {n2_from: null, n2_to: null, n3_from: null, n3_to: null, n4_from: null, n4_to: null, n5_from: null, n5_to: null},
                                    };
            this.item_data.loan_value = null;

            if(this.item_data.id_document_const_type == 22){
                this.item_data.cao = null;
            }else if(this.item_data.id_document_const_type != 22){
                this.item_data.set_values.is_night_shift = 0;
                this.item_data.set_values.is_saturday = 0;
                this.item_data.set_values.is_sunday = 0;
                this.item_data.set_values.is_sleep_shift = 0;
                this.item_data.set_values.is_full_care = 0;
                this.item_data.set_values.is_holidays = 0;
                this.item_data.set_values.two_periods_values = [4, 17, 28];
                this.item_data.set_values.tree_periods_values = [4, 17, 19, 28];
                this.item_data.set_values.weekend_values = [15, 19];
                this.item_data.set_values.saturday_values = [1, 25];
                this.item_data.set_values.sunday_values = [1, 25];
            }

            this.item_data.duration_year = null;
            this.item_data.hour_value = null;
            this.item_data.functions = null;
            this.item_data.upload_contract = null
            this.item_data.documents = [];
            this.item_data.zzp_error = 0;
            this.item_data.documents_error = 0;

        },

        goClientCard: function(){
            this.$router.push({ name: "clients-profile-organization", params: { id_client_data_main: this.id_client_data_main } });
        }
    },
};
</script>

<style>
    .app-webform-light-button {
        color: rgba(0, 0, 0, 0.5);
    }

    .app-webform-light-button:hover,  .app-webform-light-button:focus {
        color: rgba(0, 0, 0, 0.7);
    }

    .bg-light{
        background-color: white !important;
    }
</style>
