<template>
    <div>
        
        <b-card class="mb-3" body-class="pb-3">
            
            <b-row class="app-card-header mb-3">
                <b-col start>
                    <div class="app-card-tittle">
                        <div> {{trans('cw-tariff-periods',194)}} </div>    
                    </div>
                </b-col>
            </b-row>

            
            <label class="app-input-top-label pb-2">{{trans('cw-tariff-periods',194)}}</label>
            <b-row v-if="cSetValues.is_night_shift == 1">
                <b-col ref="slider-col" id="slider-col">
                    <div style="transform-origin: left;" :style="{width: width + 'px', zoom: zoom, transform: 'scale('+scales[zoom]+')'}">
                        <vue-slider :zoom="scales[zoom]" class="mt-4 mb-5 ml-3 mr-3" v-model="cSetValues.two_periods_values" tooltip="always" tooltip-placement="bottom" :marks="true" :hide-label="true" :data="hour_scale" :data-value="'id'" :data-label="'hour'" 
                                    :process="two_periods_process" :min-range="2" :dot-options="two_periods_limitation" @change="changeTwoPeriods" :adsorb="true" :lazy="true" :duration="0" :tooltipStyle="tooltip_style" >
                            <template v-slot:process="{style, index }">
                                <div class="vue-slider-process" :style="style">
                                    <div :class="['vue-slider-dot-tooltip-inner', 'vue-slider-dot-tooltip-inner-top', 'merge-tooltip', ]">
                                    {{two_periods_name[index]}}
                                    </div>
                                </div>
                            </template>    
                        </vue-slider>
                    </div>
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col ref="slider-col">
                    <div style="transform-origin: left;" :style="{width: width + 'px', zoom: zoom, transform: 'scale('+scales[zoom]+')'}">
                        <vue-slider :zoom="scales[zoom]" class="mt-4 mb-5 ml-3 mr-3" v-model="cSetValues.tree_periods_values" tooltip="always" tooltip-placement="bottom" :marks="true" :hide-label="true" :data="hour_scale" :data-value="'id'" :data-label="'hour'" 
                                    :process="tree_periods_process" :min-range="2" :dot-options="tree_periods_limitation" @change="changeTreePeriods" :adsorb="true" :lazy="true" :duration="0" :tooltipStyle="tooltip_style">
                            <template v-slot:process="{style, index }">
                                <div class="vue-slider-process" :style="style">
                                    <div :class="['vue-slider-dot-tooltip-inner', 'vue-slider-dot-tooltip-inner-top', 'merge-tooltip', ]">
                                    {{tree_periods_name[index]}}
                                    </div>
                                </div>
                            </template>    
                        </vue-slider>
                    </div>
                </b-col>
            </b-row>
            
            <b-row  class="mb-3">
                <b-col>
                    <app-check-box v-model="cSetValues.is_night_shift" value="1" unchecked-value="0" :rightLabel="trans('cw-evening-and-night-merging',194)" />
                </b-col>
            </b-row>

            <hr class="app-local-line" />
            <label class="app-input-top-label">{{trans('cw-weekend-format',194)}}</label>
            <b-row>
                <b-col>
                    <div style="transform-origin: left;" :style="{width: width + 'px', zoom: zoom, transform: 'scale('+scales[zoom]+')'}">
                        <vue-slider :zoom="scales[zoom]" class="mt-3 mb-5 ml-3 mr-3" v-model="cSetValues.weekend_values" tooltip="always" tooltip-placement="bottom" :marks="true" :hide-label="true" :data="weekend_scale" :data-value="'id'" :data-label="'display_hour'" 
                                    :process="weekend_process" :min-range="2" :dot-options="weekend_limitation" :adsorb="true" :lazy="true" :duration="0" :tooltipStyle="tooltip_style">
                        </vue-slider>
                    </div>
                </b-col>
            </b-row>

            <label class="app-input-top-label">{{trans('cw-add-separate-weekend-tariff',194)}}</label>
            <b-row class="mt-2 mb-3">
                <b-col cols="auto">
                    <app-check-box v-model="cSetValues.is_saturday" value="1" unchecked-value="0" :rightLabel="trans('saturday',182)" /> 
                </b-col>
                <b-col cols="auto">
                    <app-check-box v-model="cSetValues.is_sunday" value="1" unchecked-value="0" :rightLabel="trans('sunday',182)" />
                </b-col>
                <b-col>
                </b-col>
            </b-row>
            
            <b-row class="mb-2" v-if="cSetValues.is_saturday == 1 || cSetValues.is_sunday == 1">
                <b-col v-if="cSetValues.is_saturday == 1" cols="6" ref="slider-small-col-1">   
                    <label class="app-input-top-label">{{trans('saturday',182)}}</label>
                    <div style="transform-origin: left;" :style="{width: width_small + 'px', zoom: zoom, transform: 'scale('+scales[zoom]+')'}">               
                        <vue-slider :zoom="scales[zoom]" class="mt-3 mb-5 ml-3 mr-3" v-model="cSetValues.saturday_values" tooltip="always" tooltip-placement="bottom" :marks="true" :hide-label="true" :data="day_scale" :data-value="'id'" :data-label="'hour'" 
                                :process="saturday_process" :min-range="2" :adsorb="true" :lazy="true" :duration="0" :tooltipStyle="tooltip_style">
                        </vue-slider>
                    </div>
                </b-col>
                <b-col  v-if="cSetValues.is_sunday == 1" cols="6" ref="slider-small-col-2">
                    <label class="app-input-top-label">{{trans('sunday',182)}}</label>
                    <div style="transform-origin: left;" :style="{width: width_small + 'px', zoom: zoom, transform: 'scale('+scales[zoom]+')'}">                   
                        <vue-slider :zoom="scales[zoom]" class="mt-3 mb-5 ml-3 mr-3" v-model="cSetValues.sunday_values" tooltip="always" tooltip-placement="bottom" :marks="true" :hide-label="true" :data="day_scale" :data-value="'id'" :data-label="'hour'" 
                                    :process="sunday_process" :min-range="2" :adsorb="true" :lazy="true" :duration="0" :tooltipStyle="tooltip_style">
                        </vue-slider>
                    </div>
                </b-col>
            </b-row>
            <hr class="app-local-line" /> 
            <label class="app-input-top-label">{{trans('cw-adding-optional-tariffs',194)}}</label>
            <b-row class="mt-2 mb-2">
                <b-col cols="auto">
                    <app-check-box v-model="cSetValues.is_sleep_shift" value="1" unchecked-value="0" :rightLabel="trans('sleeping-service',182)" />
                </b-col>
                <b-col cols="auto">
                    <app-check-box v-model="cSetValues.is_full_care" value="1" unchecked-value="0" :rightLabel="trans('twenty-four-hour-care',190)" />
                </b-col>
                <b-col cols="auto">
                    <app-check-box v-model="cSetValues.is_holidays" value="1" unchecked-value="0" :rightLabel="trans('holidays',190)" />
                </b-col>
            </b-row>
        </b-card>
             
        

    </div>
</template>

<script>

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
    props:["periods", "setValues"],

    components:{
         VueSlider
    },

    created(){
       this.setReturnValues();
    },

    async mounted(){
        await this.$nextTick();
        this.setScale();
    },

    computed: {
        cSetValues: {
            get() {return this.setValues },
            set(value) {this.$emit('update:setValues', value) },
        },
    },

    watch:{
        "cSetValues": {
            handler: function(val) {
                this.setReturnValues();
                this.setScale();
            },
            deep: true,
        }
    },

    data(){
        return{
                tooltip_style: {
                                    color: '#174066',
                                    background: 'transparent',
                                    borderColor: 'transparent',
                                    transform: 'translate(-50%, -4px)',
                                    position: 'absolute'
                               },

                hour_scale:  [{id: 1, hour: '04:00'}, {id: 2, hour: '05:00'}, {id: 3, hour: '06:00'}, {id: 4, hour: '07:00'}, {id: 5, hour: '08:00'}, {id: 6, hour: '09:00'}, {id: 7, hour: '10:00'}, 
                              {id: 8, hour: '11:00'}, {id: 9, hour: '12:00'}, {id: 10, hour: '13:00'}, {id: 11, hour: '14:00'}, {id: 12, hour: '15:00'}, {id: 13, hour: '16:00'}, {id: 14, hour: '17:00'}, 
                              {id: 15, hour: '18:00'}, {id: 16, hour: '19:00'}, {id: 17, hour: '20:00'}, {id: 18, hour: '21:00'}, {id: 19, hour: '22:00'}, {id: 20, hour: '23:00'}, {id: 21, hour: '00:00'}, 
                              {id: 22, hour: '01:00'}, {id: 23, hour: '02:00'}, {id: 24, hour: '03:00'}, {id: 25, hour: '04:00'}, {id: 26, hour: '05:00'}, {id: 27, hour: '06:00'}, {id: 28, hour: '07:00'}, 
                              {id: 29, hour: '08:00'}, {id: 30, hour: '09:00'}, {id: 31, hour: '10:00'}], 

                weekend_scale:  [{id: 1, hour: '17:00', display_hour: 'Vr 17:00'}, {id: 2, hour: '18:00', display_hour: 'Vr 18:00'}, {id: 3, hour: '19:00', display_hour: 'Vr 19:00'}, 
                                 {id: 4, hour: '20:00', display_hour: 'Vr 20:00'}, {id: 5, hour: '21:00', display_hour: 'Vr 21:00'}, {id: 6, hour: '22:00', display_hour: 'Vr 22:00'}, 
                                 {id: 7, hour: '23:00', display_hour: 'Vr 23:00'}, {id: 8, hour: '00:00', display_hour: 'Za 00:00'}, {id: 9, hour: '01:00', display_hour: 'Za 01:00'}, 
                                 {id: 10, hour: '02:00', display_hour: 'Za 02:00'}, {id: 11, hour: '03:00', display_hour: 'Za 03:00'}, {id: 12, hour: '04:00', display_hour: 'Za 04:00'}, 
                                 {id: 13, hour: '05:00', display_hour: 'Za 05:00'}, {id: 14, hour: '06:00', display_hour: 'Za 06:00'}, {id: 15, hour: '07:00', display_hour: 'Za 07:00'}, 
                                 {id: 16, hour: '12:00', display_hour: 'Za 12:00'}, {id: 17, hour: '00:00', display_hour: 'Zo 00:00'}, {id: 18, hour: '12:00', display_hour: '12:00'}, 
                                 {id: 19, hour: 'Ma 00:00', display_hour: 'Ma 00:00'}, {id: 20, hour: '01:00', display_hour: 'Ma 01:00'}, {id: 21, hour: '02:00', display_hour: 'Ma 02:00'}, 
                                 {id: 22, hour: '03:00', display_hour: 'Ma 03:00'}, {id: 23, hour: '04:00', display_hour: 'Ma 04:00'}, {id: 24, hour: '05:00', display_hour: 'Ma 05:00'}, 
                                 {id: 25, hour: '06:00', display_hour: 'Ma 06:00'}, {id: 26, hour: '07:00', display_hour: 'Ma 07:00'}], 
                day_scale:  [{id: 1, hour: '00:00'}, {id: 2, hour: '01:00'}, {id: 3, hour: '02:00'}, {id: 4, hour: '03:00'}, {id: 5, hour: '04:00'}, {id: 6, hour: '05:00'}, {id: 7, hour: '06:00'}, 
                             {id: 8, hour: '07:00'}, {id: 9, hour: '08:00'}, {id: 10, hour: '09:00'}, {id: 11, hour: '10:00'}, {id: 12, hour: '11:00'}, {id: 13, hour: '12:00'}, {id: 14, hour: '13:00'}, 
                             {id: 15, hour: '14:00'}, {id: 16, hour: '15:00'}, {id: 17, hour: '16:00'}, {id: 18, hour: '17:00'}, {id: 19, hour: '18:00'}, {id: 20, hour: '19:00'}, {id: 21, hour: '20:00'}, 
                             {id: 22, hour: '21:00'}, {id: 23, hour: '22:00'}, {id: 24, hour: '23:00'}, {id: 25, hour: '24:00'}],
               
                two_periods_limitation: [{ max: 7}, {}, {min : 25}],
                two_periods_name: [this.trans('day-part-day',182), this.trans('cp-evening-and-night',192)],
                two_periods_process: dotsPos => [
                    [dotsPos[0], dotsPos[1]],
                    [dotsPos[1], dotsPos[2]]
                ],
 
                tree_periods_limitation: [{ max: 7}, {}, {}, {min : 25}],
                tree_periods_name: [this.trans('day-part-day',182), this.trans('day-part-evening',182), this.trans('day-part-night',182)],
                tree_periods_process: dotsPos => [
                    [dotsPos[0], dotsPos[1]],
                    [dotsPos[1], dotsPos[2]],
                    [dotsPos[2], dotsPos[3]]
                ],

                weekend_limitation: [{ max: 15}, {min : 19}],
                weekend_process: dotsPos => [
                    [dotsPos[0], dotsPos[1]]
                ],

                saturday_process: dotsPos => [
                    [dotsPos[0], dotsPos[1]]
                ],

                sunday_process: dotsPos => [
                    [dotsPos[0], dotsPos[1]]
                ],
            width: 0,
            width_small: 0,
            zoom: 1,
            scales: { '1.10': 0.9, '1.25': 0.8, '1.50': 0.67, '1.75': 0.55, '2.00': 0.5}
        }
    },

    methods: {
        changeTwoPeriods(value, index) {

            if (index == 0 || this.cSetValues.two_periods_values[1] - this.cSetValues.two_periods_values[0] == 2) {
                var delta = this.cSetValues.two_periods_values[0] + 24 - this.cSetValues.two_periods_values[2];
                if (delta != 0) {
                    
                    this.cSetValues.two_periods_values[2] = this.cSetValues.two_periods_values[2] + delta;
                    if (index != 1) {
                        this.cSetValues.two_periods_values[1] = this.cSetValues.two_periods_values[1] + delta;
                    }                     
                } 
            } else if (index == 2 || this.cSetValues.two_periods_values[2] - this.cSetValues.two_periods_values[1] == 2) {
                var delta = this.cSetValues.two_periods_values[0] + 24 - this.cSetValues.two_periods_values[2];
                if (delta != 0) {
                    
                    this.cSetValues.two_periods_values[0] = this.cSetValues.two_periods_values[0] - delta;
                    if (index != 1) {
                        this.cSetValues.two_periods_values[1] = this.cSetValues.two_periods_values[1] - delta;
                    }                    
                }
            }
            
        },

        changeTreePeriods(value, index) {

            if (index == 0 || this.cSetValues.tree_periods_values[1] - this.cSetValues.tree_periods_values[0] == 2) {
                var delta = this.cSetValues.tree_periods_values[0] + 24 - this.cSetValues.tree_periods_values[3];
                if (delta != 0) {
                    this.cSetValues.tree_periods_values[3] = this.cSetValues.tree_periods_values[3] + delta;
                   
                    if (index != 2) {
                        this.cSetValues.tree_periods_values[2] = this.cSetValues.tree_periods_values[2] + delta;
                    }
                    
                    if (index != 1 && this.cSetValues.tree_periods_values[2] - this.cSetValues.tree_periods_values[1] != 2) {
                        this.cSetValues.tree_periods_values[1] = this.cSetValues.tree_periods_values[1] + delta;  
                    }
                                       
                }             
            } else if (index == 3 || this.cSetValues.tree_periods_values[3] - this.cSetValues.tree_periods_values[2] == 2) {
                var delta = this.cSetValues.tree_periods_values[0] + 24 - this.cSetValues.tree_periods_values[3];
                if (delta != 0) {
                    this.cSetValues.tree_periods_values[0] = this.cSetValues.tree_periods_values[0] - delta;
                    
                    if (index != 2 && this.cSetValues.tree_periods_values[2] - this.cSetValues.tree_periods_values[1] != 2) {
                        this.cSetValues.tree_periods_values[2] = this.cSetValues.tree_periods_values[2] - delta;   
                    }

                    if (index != 1) {
                        this.cSetValues.tree_periods_values[1] = this.cSetValues.tree_periods_values[1] - delta;    
                    }                
                }
            }
           
        },

        setReturnValues() {
            var l_periods = [];
           if (this.cSetValues.is_night_shift == 0) {
                
                var day_start = this.hour_scale.find(element => element.id == this.cSetValues.tree_periods_values[0]).hour;
                var day_end = this.hour_scale.find(element => element.id == this.cSetValues.tree_periods_values[1]).hour;

                var evening_start = this.hour_scale.find(element => element.id == this.cSetValues.tree_periods_values[1]).hour;
                var evening_end = this.hour_scale.find(element => element.id == this.cSetValues.tree_periods_values[2]).hour;
                
                var night_start = this.hour_scale.find(element => element.id == this.cSetValues.tree_periods_values[2]).hour;
                var night_end = this.hour_scale.find(element => element.id == this.cSetValues.tree_periods_values[3]).hour;
                
                l_periods.push({type: 1, display_name: this.trans('day-part-day',182) + ' (' + day_start + ' - ' + day_end + ')', from: day_start, to: day_end});
                l_periods.push({type: 2, display_name: this.trans('day-part-evening',182) + ' (' + evening_start + ' - ' + evening_end + ')', from: evening_start, to: evening_end});
                l_periods.push({type: 3, display_name: this.trans('day-part-night',182) + ' (' + night_start + ' - ' + night_end + ')', from: night_start, to: night_end});

            } else {

                var day_start = this.hour_scale.find(element => element.id == this.cSetValues.two_periods_values[0]).hour;
                var day_end = this.hour_scale.find(element => element.id == this.cSetValues.two_periods_values[1]).hour;

                var evening_start = this.hour_scale.find(element => element.id == this.cSetValues.two_periods_values[1]).hour;
                var evening_end = this.hour_scale.find(element => element.id == this.cSetValues.two_periods_values[2]).hour;
                
                l_periods.push({type: 1, display_name: this.trans('day-part-day',182) + ' (' + day_start + ' - ' + day_end + ')', from: day_start, to: day_end});
                l_periods.push({type: 2, display_name: this.trans('day-part-night',182) + ' (' + evening_start + ' - ' + evening_end + ')', from: evening_start, to: evening_end});
            }

            var weekend_start = this.weekend_scale.find(element => element.id == this.cSetValues.weekend_values[0]).hour;
            var weekend_start_display = this.weekend_scale.find(element => element.id == this.cSetValues.weekend_values[0]).display_hour;
            var weekend_end = this.weekend_scale.find(element => element.id == this.cSetValues.weekend_values[1]).hour;
            var weekend_end_display = this.weekend_scale.find(element => element.id == this.cSetValues.weekend_values[1]).display_hour;

            l_periods.push({type: 4, display_name: this.trans('weekend',192) + ' (' + weekend_start_display + ' - ' + weekend_end_display + ')', from: weekend_start, to: weekend_end});

            if(this.cSetValues.is_saturday == 1) {
                var saturday_start = this.day_scale.find(element => element.id == this.cSetValues.saturday_values[0]).hour;
                var saturday_end = this.day_scale.find(element => element.id == this.cSetValues.saturday_values[1]).hour;
                l_periods.push({type: 5, display_name: this.trans('saturday',182) + ' (' + saturday_start + ' - ' + saturday_end + ')', from: saturday_start, to: saturday_end});
            }

            if(this.cSetValues.is_sunday == 1) {
                var sunday_start = this.day_scale.find(element => element.id == this.cSetValues.sunday_values[0]).hour;
                var sunday_end = this.day_scale.find(element => element.id == this.cSetValues.sunday_values[1]).hour;
                l_periods.push({type: 6, display_name: this.trans('sunday',182) + ' (' + sunday_start + ' - ' + sunday_end + ')', from: sunday_start, to: sunday_end});
            }

            if(this.cSetValues.is_sleep_shift == 1) {
                l_periods.push({type: 7, display_name: this.trans('sleeping-service',182)});
            }
            
            if(this.cSetValues.is_full_care == 1) {
                l_periods.push({type: 8, display_name: this.trans('twenty-four-hour-care',190)});
            }

            if(this.cSetValues.is_holidays == 1) {
                l_periods.push({type: 9, display_name: this.trans('holidays',190)});
            }
            
            
            this.$emit('update:periods', {shifts: l_periods, values: this.cSetValues});            
        },

        async setScale(){
            this.width = this.$refs['slider-col'].clientWidth - 20;
            this.zoom = window.devicePixelRatio.toFixed(2);
            await this.$nextTick();
            
            if(this.cSetValues.is_saturday == "1"){
                this.width_small = this.$refs['slider-small-col-1'].clientWidth - 20;

            } else if(this.cSetValues.is_sunday == "1"){
                this.width_small = this.$refs['slider-small-col-2'].clientWidth - 20;
            }
        }
    }

    
}
</script>

<style scoped>
 .merge-tooltip {
      position: absolute;
      left: 50%;
      bottom: 100%;
      width: calc(100% - 12px);
      transform: translate(-50%, -10px);
      border: none;
      background-color: #174066;
      text-overflow:ellipsis; 
      white-space: nowrap;
      overflow: hidden;
    }

.vue-slider-dot-tooltip-inner-bottom::after {
    color: red !important;
}

</style>