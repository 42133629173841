<template>
    <div> 
        <b-row class="p-0 m-0">
            <b-col class="p-0 m-0">
                <label class="flabel"> {{label}} </label>
            </b-col>
            <b-col class="p-0 m-0" cols="auto" end v-if="cMenu && isMenu != false">
                <b-dropdown  variant="link" no-caret right toggle-class="p-0 m-0" style="margin-top:-4px;">
                    <template #button-content>
                        <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                    </template>
                    <b-dropdown-item v-if="cMenuItemCopyFirstToAll" @click="copyToAll">{{trans('cw-copy-first-to-rest',194)}}</b-dropdown-item>
                    
                    <b-dropdown-divider v-if="cMenuItemDivider01"></b-dropdown-divider>                    
                    
                    <b-dropdown-item v-if="cMenuItemCopyToPhaseA" @click="copyToColumn('p_a')">{{trans('cw-copy-to-phase-a',194)}}</b-dropdown-item>
                    <b-dropdown-item v-if="cMenuItemCopyToPhaseB" @click="copyToColumn('p_b')">{{trans('cw-copy-to-phase-b',194)}}</b-dropdown-item>
                    <b-dropdown-item v-if="cMenuItemCopyToPhaseC" @click="copyToColumn('p_c')">{{trans('cw-copy-to-phase-c',194)}}</b-dropdown-item>
                    <b-dropdown-item v-if="cMenuItemCopyToPhaseAll" @click="copyToColumn('p_all')">{{trans('cw-copy-to-all-phases',194)}}</b-dropdown-item>
                    <b-dropdown-item v-if="cMenuItemCopyToOrt" @click="copyToColumn('ort')">{{trans('cw-copy-to-ort',194)}}</b-dropdown-item> 
                    
                    <b-dropdown-divider v-if="cMenuItemDivider02"></b-dropdown-divider>
                    
                    <b-dropdown-item v-if="cMenuItemCopyToPhaseAssigmentA" @click="copyToColumn('ap_a')">{{trans('cw-copy-to-phase-a-posting',194)}}</b-dropdown-item>
                    <b-dropdown-item v-if="cMenuItemCopyToPhaseAssigmentB" @click="copyToColumn('ap_b')">{{trans('cw-copy-to-phase-b-posting',194)}}</b-dropdown-item>
                    <b-dropdown-item v-if="cMenuItemCopyToPhaseAssigmentC" @click="copyToColumn('ap_c')">{{trans('cw-copy-to-phase-c-posting',194)}}</b-dropdown-item>
                    <b-dropdown-item v-if="cMenuItemCopyToPhaseAssigmentAll" @click="copyToColumn('ap_all')">{{trans('cw-copy-to-all-phases-posting',194)}}</b-dropdown-item>
                    <b-dropdown-item v-if="cMenuItemCopyToAssigmentOrt" @click="copyToColumn('a_ort')">{{trans('cw-copy-to-ort-posting',194)}}</b-dropdown-item>
                   
                    <b-dropdown-divider v-if="cMenuItemDivider03"></b-dropdown-divider>
                   
                    <b-dropdown-item v-if="cMenuItemCopyShiftToAssigment" @click="copyShiftToAssigment">{{trans('cw-copy-all-broadcast-to-posting',194)}}</b-dropdown-item>
                    
                    <b-dropdown-divider v-if="cMenuItemDivider04"></b-dropdown-divider>
                   
                    <b-dropdown-item v-if="cMenuItemClear" @click="clearColumn">{{trans('cw-clear-column',194)}}</b-dropdown-item>                                             
                </b-dropdown>
            </b-col>
        </b-row>               
    </div>
</template>

<script>

export default {
    props:['itemData', 'label', 'labelId', 'copyObject', 'isMenu'],

    components:{
        
    },
    
    computed: {

        cCopyObject: {
            get() {return this.copyObject },
            set(value) {this.$emit('update:copyObject', value) },
        },

        cItemData: {
            get() {return this.itemData },
            set(value) {this.$emit('update:itemData', value) },
        },

        cMenu: {
            get() {
                return this.itemData.uzk_parameters.diff_function == 1 || this.itemData.uzk_parameters.diff_phase == 1 || this.itemData.uzk_parameters.diff_assigment == 1 || (this.itemData.uzk_parameters.diff_overhour == 1 && this.itemData.uzk_parameters.calculation_type == 2) ? true : false;
            }
        },

        cMenuItemCopyFirstToAll: {
            get() {
                return this.itemData.uzk_parameters.diff_function == 1 ? true : false;
            }
        },

        cMenuItemDivider01: {
            get() {
                return this.itemData.uzk_parameters.diff_function == 1 ? true : false;
            }
        },

        cMenuItemCopyToPhaseA: {
            get() {
                return this.labelId != 'p_a' && this.itemData.uzk_parameters.diff_phase == 1 ? true : false;
            }
        },

        cMenuItemCopyToPhaseB: {
            get() {
                return this.labelId != 'p_b' && this.itemData.uzk_parameters.diff_phase == 1 ? true : false;
            }
        },

        cMenuItemCopyToPhaseC: {
            get() {
                return this.labelId != 'p_c' && this.itemData.uzk_parameters.diff_phase == 1 ? true : false;
            }
        },

        cMenuItemCopyToPhaseAll: {
            get() {
                return this.labelId != 'p_all' && this.itemData.uzk_parameters.diff_phase == 0 ? true : false;
            }
        },

        cMenuItemCopyToOrt: {
            get() {
                return this.labelId != 'ort' && this.itemData.uzk_parameters.diff_overhour == 1 && this.itemData.uzk_parameters.calculation_type == 2 ? true : false;
            }
        },

        cMenuItemDivider02: {
            get() {
                return this.itemData.uzk_parameters.diff_assigment == 1 ? true : false;
            }
        },

        cMenuItemCopyToPhaseAssigmentA: {
            get() {
                return this.labelId != 'ap_a' && this.itemData.uzk_parameters.diff_phase == 1 && this.itemData.uzk_parameters.diff_assigment == 1 ? true : false;
            }
        },

        cMenuItemCopyToPhaseAssigmentB: {
            get() {
                return this.labelId != 'ap_b' && this.itemData.uzk_parameters.diff_phase == 1 && this.itemData.uzk_parameters.diff_assigment == 1 ? true : false;
            }
        },

        cMenuItemCopyToPhaseAssigmentC: {
            get() {
                return this.labelId != 'ap_c' && this.itemData.uzk_parameters.diff_phase == 1 && this.itemData.uzk_parameters.diff_assigment == 1 ? true : false;
            }
        },

        cMenuItemCopyToPhaseAssigmentAll: {
            get() {
                return this.labelId != 'ap_all' && this.itemData.uzk_parameters.diff_phase == 0 && this.itemData.uzk_parameters.diff_assigment == 1 ? true : false;
            }
        },

        cMenuItemCopyToAssigmentOrt: {
            get() {
                return this.labelId != 'a_ort' && this.itemData.uzk_parameters.diff_overhour == 1 && this.itemData.uzk_parameters.calculation_type == 2 && this.itemData.uzk_parameters.diff_assigment == 1 ? true : false;
            }
        },

        cMenuItemDivider03: {
            get() {
                return this.itemData.uzk_parameters.diff_assigment == 1 ? true : false;
            }
        },

        cMenuItemCopyShiftToAssigment: {
            get() {
                return this.itemData.uzk_parameters.diff_assigment == 1 ? true : false;
            }
        },

        cMenuItemDivider04: {
            get() {
                return this.itemData.uzk_parameters.diff_function == 1 ? true : false;
            }
        },

        cMenuItemClear: {
            get() {
                return this.itemData.uzk_parameters.diff_function == 1 ? true : false;
            }
        },
        
    },

    data(){
        return{

        }
    },

    methods: {

        copyToAll() {
            this.cCopyObject.operation_type = 1;
            this.cCopyObject.column_from = this.labelId;
            this.cCopyObject.column_to = null;
            this.cCopyObject.refresh++;
        },

        copyToColumn(columnTo) {
            this.cCopyObject.operation_type = 2;
            this.cCopyObject.column_from = this.labelId;
            this.cCopyObject.column_to = columnTo;
            this.cCopyObject.refresh++;
        },
        
        copyShiftToAssigment() {
            this.cCopyObject.operation_type = 3;
            this.cCopyObject.column_from = null;
            this.cCopyObject.column_to = null;
            this.cCopyObject.refresh++;
        },
        
        clearColumn() {
            this.cCopyObject.operation_type = 4;
            this.cCopyObject.column_from = this.labelId;;
            this.cCopyObject.column_to = null;
            this.cCopyObject.refresh++;
        }
    }

    
}
</script>

<style scoped>
.app-local-line {
    width:100%; 
    border-top: 1px solid #e6e6e6 !important;  
    margin-top:5px !important ;
    margin-bottom: 10px !important;   
}

.app-local-bg-assigment {
background-color: #F9FAFB;
}

</style>